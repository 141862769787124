import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import {
  FaPhone,
  FaFacebookF,
  FaFacebookMessenger,
  FaInstagram,
  FaLine,
  FaFileAlt,
} from "react-icons/fa"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          author
          mobile
          facebook
          instagram
          line
        }
      }
      hermithead: file(
        relativePath: { eq: "services/head/hermit-head-01.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title={`${data.site.siteMetadata.author} | เกี่ยวกับเรา`} />
      <div className="container text-center mt-5 mb-5">
        <h1>เกี่ยวกับเรา</h1>
        <div class="line-center "></div>
        <p className="title">
          Seen Facial Spa สปานวดหน้า ปรนนิบัติผิวหน้าด้วยวิถีธรรมชาติ
          (ออร์แกนิก) พร้อมด้วยศาสตร์การกดจุดผ่อนคลาย การกระตุ้นการไหลเวียนเลือด
          การออกกำลังผิวหน้าถึงชั้นมัดกล้ามเนื้อด้วย "The power of the hands" ที่มีท่านวดที่เป็นเอกลักษณ์
        </p>
        <p className="title">
          พลังของมือจาก Therapitst (เทอราปิส) ผู้เชี่ยวชาญด้านศาสตร์ ชะลอวัย
          ความอบอุ่นที่อุณหภูมิ 37 องศา ผิวสัมผัสที่แตกต่างจากเครื่องมือใดใด
          ผสานกับการบำรุงล้ำลึกจากผลิตภัณฑ์ออร์แกนิกคุณภาพระดับพรี่เมี่ยม
          จึงเกิดเป็นผลลัพธ์กระตุ้นและกระชับ
          กล้ามเนื้อที่หย่อนคล้อยให้กลับมาเต่งตึงมีชีวิตชีวาคืนความอ่อนเยาว์ให้ผิวหน้าคุณ
        </p>
      </div>
      <Img fluid={data.hermithead.childImageSharp.fluid} />
    </Layout>
  )
}

export default AboutPage
